import React from "react"
import { graphql } from "gatsby"
import { Avatar } from "gatsby-interface"
import Layout from "../../layouts"
import SEO from "../../components/seo"
import Header from "../../components/header"
import { guideContainer, guidePaddingBottom } from "../../utils/styles"
import Stars from "../../components/blog/stars"
import HeroContainer from "../../components/containers/hero"
import Title from "../../components/hero/title"
import { rhythm } from "../../utils/typography"
import { mediaQueries, fontSizes, space, lineHeights } from "../../utils/tokens"
import BlogPostPreviewItem from "../../components/blog/blog-post-preview-item"
import PullBelowHeader from "../../components/containers/pull-below-header"
import Footer from "../../components/footer"

const styles = {
  container: {
    overflowX: `hidden`,
    position: `relative`,
    zIndex: 2,
  },
  authorWrapper: {
    display: `flex`,
    textAlign: `center`,
    flexDirection: `column`,
    alignItems: `center`,
    span: {
      marginBottom: space[4],
    },
    marginBottom: space[12],
  },
  bioStyles: {
    lineHeight: lineHeights.dense,
    maxWidth: rhythm(20),
    marginBottom: space[4],
  },
}

const ContributorsAuthorPage = ({ location, data: { wpUser: user } }) => {
  return (
    <Layout pathname={location.pathname}>
      <SEO
        title={`${user.name} - Contributor`}
        description={`All posts by ${user.name} on the Gatsby blog.`}
        url={location.href}
      />
      <Header />
      <PullBelowHeader>
        <div
          css={{
            ...styles.container,
            ...guidePaddingBottom,
            position: `relative`,
          }}
        >
          <Stars />
          <HeroContainer isInverted>
            <div css={guideContainer}>
              <section css={styles.authorWrapper}>
                {user.authorInfo?.avatar?.localFile && (
                  <Avatar
                    src={
                      user.authorInfo.avatar.localFile.childImageSharp.resize
                        .src
                    }
                    label={user.name}
                    size="XL"
                  />
                )}
                <Title
                  customStyles={{
                    maxWidth: rhythm(16),
                    [mediaQueries.phablet]: {
                      maxWidth: rhythm(20),
                    },
                    [mediaQueries.tablet]: {
                      fontSize: fontSizes[7],
                    },
                    [mediaQueries.hd]: {
                      fontSize: fontSizes[8],
                    },
                  }}
                >
                  {user.name}
                </Title>
                {user.authorInfo?.bio && (
                  <p css={styles.bioStyles}>{user.authorInfo.bio}</p>
                )}
                {user.authorInfo?.twitter && (
                  <p css={styles.bioStyles}>
                    <a
                      href={`https://www.twitter.com/${user.authorInfo.twitter}`}
                    >
                      {user.authorInfo.twitter}
                    </a>
                  </p>
                )}
              </section>
              {user.posts?.nodes?.map(post => (
                <BlogPostPreviewItem post={post} key={post.slug} Heading="h2" />
              ))}
            </div>
          </HeroContainer>
        </div>
      </PullBelowHeader>
      <Footer />
    </Layout>
  )
}

export default ContributorsAuthorPage

export const pageQuery = graphql`
  query ContributorsAuthorPage($id: String!) {
    wpUser(id: { eq: $id }) {
      name
      slug
      authorInfo {
        twitter
        bio
        avatar {
          localFile {
            childImageSharp {
              resize(width: 64, height: 64, quality: 100) {
                src
              }
            }
          }
        }
      }
      posts {
        nodes {
          ...BlogPostPreviewInformation
        }
      }
    }
  }
`
